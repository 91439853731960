import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";
import { isLoggedIn } from "../api";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

const renderRoute = (props, Layout, Component, requiresAuth) => {
	return !requiresAuth || isLoggedIn() ? (
		<Layout>
			<Component {...props} />
		</Layout>
	) : (
		<Redirect to="/auth/login" />
	);
};

const childRoutes = (Layout, routes, requiresAuth) => {
	return routes.map(({ children, path, component: Component }, index) =>
		children ? (
			// Route item with children
			children.map(({ path, component: Component }, index) => (
				<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth)} />
			))
		) : (
			// Route item without children
			<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth)} />
		)
	);
};

const Routes = () => (
	<Router>
		<Switch>
			{childRoutes(DashboardLayout, dashboardRoutes, true)}
			{childRoutes(AuthLayout, authRoutes, false)}
			<Route
				render={() => (
					<AuthLayout>
						<Page404 />
					</AuthLayout>
				)}
			/>
		</Switch>
	</Router>
);

export default Routes;
