import { grey } from "@material-ui/core/colors";

const orangeVariant = {
	name: "Orange",
	palette: {
		primary: {
			main: "#353d40",
			contrastText: "#FFF",
		},
		secondary: {
			main: "#353d40",
			contrastText: "#FFF",
		},
	},
	header: {
		color: grey[500],
		background: "#FFF",
		search: {
			color: grey[800],
		},
		indicator: {
			background: "#353d40",
		},
	},
	sidebar: {
		color: "#FFF",
		background: "#353d40",
		header: {
			color: "#FFF",
			background: "#353d40",
			brand: {
				color: "#3a4244",
			},
		},
		footer: {
			color: "#FFF",
			background: "#353d40",
			online: {
				background: "#FFF",
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: "#000",
			background: "#FFF",
		},
	},
	body: {
		background: "#F7F9FC",
	},
	footer: {
		color: grey[800],
	},
};

const variants = [orangeVariant];

export default variants;
